.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
  transition: .3s opacity ease-in-out;

  &--is-opened {
    opacity: 1;
    z-index: 1;
  }

  &__content {
    --contentWidth: 400px;
    --contentHeight: 260px;

    position: absolute;
    top: calc(50% - var(--contentHeight));
    left: calc(50% - var(--contentWidth));
    width: var(--contentWidth);
    height: var(--contentHeight);
    border-radius: 10px;
    padding: 70px 20px 20px 20px;
    opacity: 0;
    background-color: var(--white);
    transform: translate(50%, 50%) scale(.7);
    z-index: 1;
    transition: .3s all ease-in-out;

    &--is-opened {
      opacity: 1;
      transform: translate(50%, 50%) scale(1);
    }
  }

  &__icon {
    --dimensions: 40px;

    position: absolute;
    left: 15px;
    top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--dimensions);
    height: var(--dimensions);
    border-radius: 50%;
    background-color: var(--themeGray50);
  }

  &__close {
    --dimensions: 40px;

    position: absolute;
    right: 15px;
    top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--dimensions);
    height: var(--dimensions);

    &:hover {
      cursor: pointer;
    }
  }

  &__title {
    margin: 0 0 10px 0;
    text-align: center;
    font-size: $base;
    font-weight: $bold;
    color: var(--themeCorporate600);
  }

  &__text-container {
    position: relative;
    min-height: 75px;
  }

  &__text {
    margin: 0 0 5px 0;
    text-align: center;
    font-size: $xs;
  }

  &__number {
    display: inline-block;
    padding: 2px 6px;
    font: 12px ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
    color: var(--themeRed500);
    background-color: rgba(239, 53, 63, .25);
    border-radius: 4px;
    transform: translateY(-1px);
    user-select: none;
  }

  &__input {
    width: 100%;
    height: 30px;
    border: 1px solid var(--themeCorporate600);
    border-radius: 8px;
    outline: none;
    padding: 5px 12px;
    text-align: center;
    font-size: $sm;
    color: var(--themeCorporate600);
    transition: .3s all ease-in-out;

    &:focus {
      border-color: var(--themeCorporate600);
      box-shadow: inset 0 0 0 1px var(--themeCorporate600);
    }

    &--is-error {
      border-color: var(--themeRed500);
      box-shadow: inset 0 0 0 1px var(--themeRed500);
    }
  }

  &__error {
    position: absolute;
    left: -10px;
    bottom: -5px;
    opacity: 0;
    visibility: hidden;
    font-size: 12px;
    font-style: italic;
    color: var(--themeRed500);
    transition: .3s all ease-in-out;

    &--is-shown {
      left: 10px;
      opacity: 1;
      visibility: visible;
    }
  }

  &__actions {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    margin: 20px 0;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 175px;
    height: 42px;
    border: 2px solid var(--themeCorporate500);
    border-radius: 20px;
    font-size: $sm;
    font-weight: $bold;
    color: var(--themeCorporate500);
    background-color: transparent;
    transition: .3s all ease-in-out;

    &:hover {
      cursor: pointer;
    }

    &--type {
      &-1 {
        &:hover {
          color: var(--white);
          background-color: var(--themeCorporate500);
        }
      }

      &-2 {
        color: var(--white);
        background-color: var(--themeCorporate500);

        &:hover {
          color: var(--themeCorporate500);
          background-color: var(--white);
        }
      }
    }
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: -1;
  }
}