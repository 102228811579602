.toast {
  --toast-success-color: #3ebd61;
  --toast-info-color: #006CE3;
  --toast-warning-color: #EF9400;
  --toast-danger-color: #EC4D2B;
  --toast-width: 400px;

  position: fixed;
  top: 15px;
  right: calc(var(--toast-width) * -1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: var(--toast-width);
  border: 2px solid;
  border-radius: 16px;
  padding: 10px 12px;
  z-index: 1;
  transition: .2s right ease-in;

  &--is-visible {
    transition: .2s right ease-in;
    right: 15px;
  }

  &--success {
    border-color: var(--toast-success-color);
    background-color: #91D8A4;
  }

  &--info {
    border-color: var(--toast-info-color);
    background-color: #6FAAEC;
  }

  &--warning {
    border-color: var(--toast-warning-color);
    background-color: #F2C06F;
  }

  &--danger {
    border-color: var(--toast-danger-color);
    background-color: #EC9D8C;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__icon {
    --dimensions: 70px;

    display: flex;
    flex: 0 0 var(--dimensions);
    align-items: center;
    justify-content: center;
    width: var(--dimensions);
    height: var(--dimensions);
    margin-right: 16px;
    padding: 8px;
    border-radius: 50%;

    &--success {
      background-color: var(--toast-success-color);
    }

    &--info {
      background-color: var(--toast-info-color);
    }

    &--warning {
      background-color: var(--toast-warning-color);
    }

    &--danger {
      background-color: var(--toast-danger-color);
    }
  }

  &__text {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .3);
    color: var(--white);
  }

  &__close {
    --dimensions: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--dimensions);
    height: var(--dimensions);

    &:hover {
      cursor: pointer;
    }

    &__icon {
      --dimensions: 20px;

      width: var(--dimensions);
      height: var(--dimensions);
    }
  }
}