@mixin skeletonCell() {
  &:first-child {
    flex: 0 0 65px;
  }

  &:nth-child(2) {
    flex: 0 0 100px;
  }

  &:nth-child(3) {
    flex: 0 0 80px;
  }

  &__line {
    width: 100%;
    height: 20px;
  }
}

.table {
  --tableBtnBorderHover: #adadad;

  display: flex;
  flex-wrap: wrap;

  &__filter {
    --fieldHeight: 30px;

    position: relative;
    display: flex;
    align-items: center;

    &__label {
      margin-right: 5px;
    }

    &__field {
      height: var(--fieldHeight);
      border: 1px solid var(--themeGray100);
      border-radius: 0;
      padding: 5px 30px 5px 10px;
      font-size: 12px;
      line-height: 1.5;
      color: #555;
      background-color: var(--white);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .08);
      transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

      &:focus {
        outline: none;
        border-color: #CCD0D7;
        box-shadow: none;
      }
    }

    &__clear {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--fieldHeight);
      height: var(--fieldHeight);

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__no-data {
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid var(--themeGray100);
    background-color: var(--white);

    &__msg {
      margin: 25px 0;
      text-align: center;
      color: var(--themeBaseBlack);
    }
  }

  &__actions {
    display: flex;
    margin: 0 !important;
  }

  &__action-btn {
    border: 1px solid var(--themeGray100);
    border-radius: 0;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    color: var(--themeGray700);
    background-color: var(--white);
    transition: .3s all ease-in-out;

    &:first-child {
      border-right: none;
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      border-left: none;
      border-radius: 0 3px 3px 0;
    }

    &:hover {
      background-color: #e6e6e6;
      border-color: var(--tableBtnBorderHover);
      cursor: pointer;
    }
    
    &--is-disabled {
      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &__payment-method-cell {
    display: flex;
    align-items: center;
    justify-content: center;

    &__img {
      display: block;
      height: 32px;
      margin-right: 5px;
    }

    &__text {
      display: block;
      width: 90px;
      white-space: normal;
    }
  }

  & > header {
    width: 50%;
    padding: 0;
  }

  & > .rdt_TableHeader {
    width: 50%;
    padding: 0;

    & > div {
      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        justify-content: flex-start;
      }
    }
  }

  & > div {
    &:last-child {
      width: 100%;
    }
  }

  &__actions-cell {
    display: flex;
    align-items: center;

    &__icon {
      margin-right: 5px;
      transition: .3s all ease-in-out;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        cursor: pointer;
        opacity: .7;
      }
    }
  }

  &__copy-table {
    table-layout: fixed;
    width: 100%;
    border: 1px solid var(--themeGray400);
    border-collapse: separate;
    border-spacing: 0;
    font-family: Montserrat, "Helvetica Neue", Roboto, Arial, "Droid Sans", sans-serif;
    font-size: 14px;
    color: var(--themeBaseBlack);

    &__head,
    &__body {
      &__cell {
        border-bottom: 1px solid var(--themeGray400);
        padding: 5px;
        vertical-align: middle;

        &:first-child {
          border-left: 1px solid var(--themeGray400);
        }

        &:last-child {
          border-right: 1px solid var(--themeGray400);
        }
      }
    }

    &__head {
      &__cell {
        border-top: 1px solid var(--themeGray400);
      }
    }

    &__wrapper {
      position: absolute;
      left: -1000%;
      top: -1000%;
    }
  }

  .rdt {
    &_TableHeadRow {
      min-height: 13px;
      font-size: 10px;
      font-weight: $bold;
      text-transform: uppercase;
      color: var(--themeGray400);
    }

    &_TableRow {
      border-color: var(--themeGray100);
      background-color: transparent;
    }

    &_TableCol {
      padding: 6px 8px;

      &:last-child {
        border-right: none;
      }

      &_Sortable {
        &:hover {
          opacity: 1;

          span {
            opacity: .7;
          }
        }

        span {
          --dimensions: 14px;

          display: flex;
          align-items: center;
          justify-content: center;
          width: var(--dimensions);
          height: var(--dimensions);
          font-size: 18px;
          transition: .3s all ease-in-out;
        }
      }
    }

    &_TableCell {
      padding: 16px 5px;
      font-size: $xs;
      line-height: 16px;
      color: var(--themeBaseBlack);

      div {
        @include ellipsis();
      }
    }

    &_Pagination {
      color: var(--themeGray400);

      button {
        fill: var(--themeGray400);

        &:disabled {
          fill: var(--themeGray100);
          cursor: not-allowed;
        }
      }
    }
  }

  &__skeleton-item {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 13px;

    &__actions {
      width: 50%;

      &__line {
        width: 160px;
        height: 30px;
      }
    }

    &__filter {
      width: 50%;

      &__line {
        width: 230px;
        height: 30px;
        margin-left: auto;
      }
    }

    &__table {
      width: 100%;
      margin-top: 13px;

      &__head {
        display: flex;
        align-items: center;
        min-height: 27px;
        border-bottom: 1px solid var(--themeGray100);

        &__cell {
          width: 100%;
          padding: 0 5px;

          @include skeletonCell();
        }
      }

      &__row {
        display: flex;
        align-items: center;
        height: 81px;
        border-bottom: 1px solid var(--themeGray100);

        &__cell {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 6px 5px;

          @include skeletonCell();
        }
      }
    }

    &__footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 12px;

      &__line {
        width: 500px;
        height: 30px;
      }
    }
  }
}